import classnames from 'classnames';
import React from 'react';
import styles from './Screen.module.scss';

interface Props {
  className?: string;
}

const Screen: React.FC<Props> = ({ children, className }) => (
  <div className={classnames(styles.container, className)}>{children}</div>
);

export default Screen;
