import { lighten, grayscale } from 'polished';

const blue1: string = 'rgb(22, 109, 171)';
const blue2: string = 'rgb(26, 192, 239)';
const blue3: string = 'rgb(81, 63, 139)';
const gray1: string = 'rgb(241, 242, 242)';
// const gray2: string = 'rgb(167, 169, 172)';
// const gray3: string = 'rgb(88, 89, 91)';

const cloudalert: { [key: string]: string } = {
  text: '#38927',
  'text-secondary': '#253557',
  'text-input': '#253557',
  'text-label': '#253557',

  primary: blue2,
  'primary-dimmed': lighten(0.1, blue1),

  secondary: blue2,
  'secondary-dimmed': lighten(0.2, blue2),

  dark: '#4B418A',
  'dark-secondary': '#2C5699',
  'dark-dimmed': lighten(0.5, grayscale(blue3)),

  'border-primary': gray1,
  'border-secondary': '#D5E1EE',
  'border-dark': blue2,

  background: '#FFFFFF',
  'background-body': gray1,
  'background-input': '#FFFFFF',

  success: blue2,
  warning: '#CFA609',
  danger: '#F04D0C',
};

export default cloudalert;
