import React, { createContext } from 'react';
import { User } from 'types/user';
import { Customer } from 'types/customer';

export interface Action {
  type: 'set' | 'reset';
  payload: Partial<UserData>;
}

export type UserData = User & {
  customer?: Customer;
};

export const initialUserData: UserData = {
  id: '',
  isActive: false,
  isAdmin: false,
  login: '',
  role: '',
};

export const userReducer = (state: UserData, { type, payload }: Action): UserData => {
  switch (type) {
    case 'set':
      return { ...state, ...payload };
    case 'reset':
    default:
      return initialUserData;
  }
};

interface UserContext {
  state: UserData;
  dispatch: React.Dispatch<Action>;
}

export default createContext<UserContext>({ state: initialUserData, dispatch: () => null });
