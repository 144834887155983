import { RemoteData } from '@devexperts/remote-data-ts';
import { Api } from 'hooks/useApi';
import { createContext } from 'react';
import { AppError } from 'types/error';
import { User } from 'types/user';

interface RoutingContext {
  loginApi: Api<User>;
  loginApiData: RemoteData<AppError, User>;
}

export default createContext<RoutingContext>({
  loginApi: {} as Api<User>,
  loginApiData: {} as RemoteData<AppError, User>,
});
