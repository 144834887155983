import { initial, pending } from '@devexperts/remote-data-ts';
import { AxiosRequestConfig } from 'axios';
import { useSafeState } from 'hooks/useSafeState';
import React from 'react';
import { request as remoteDataRequest } from 'services/api';
import { ApiData } from 'types/api';

export interface Api<T> {
  request: (config: AxiosRequestConfig) => Promise<ApiData<T>>;
  update: (data: ApiData<T>) => Promise<ApiData<T>>;
  reset: () => void;
}

export const useApi = <T>(): [ApiData<T>, Api<T>] => {
  const [apiData, setApiData] = useSafeState<ApiData<T>>(initial);

  const update: (data: ApiData<T>) => Promise<ApiData<T>> = React.useCallback(
    (data: ApiData<T>) => {
      setApiData(data);
      return Promise.resolve(data);
    },
    [setApiData]
  );

  const request = React.useCallback(
    (config: AxiosRequestConfig) => {
      setApiData(pending);
      return remoteDataRequest<T>(config);
    },
    [setApiData]
  );

  const reset = React.useCallback(() => {
    setApiData(initial);
  }, [setApiData]);

  const api: Api<T> = React.useMemo(() => ({ request, update, reset }), [request, update, reset]);

  return [apiData, api];
};
