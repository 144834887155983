const ipintegrated: { [key: string]: string } = {
  text: '#343c44',
  'text-secondary': '#253557',
  'text-input': '#9babbc',
  'text-label': '#253557',

  primary: '#2b85f5',
  'primary-dimmed': '#e3f0fd',

  secondary: '#b3c849',
  'secondary-dimmed': '#E9F4AE',

  dark: '#121E38',
  'dark-secondary': '#253557',
  'dark-dimmed': '#EDF0F2',

  'border-primary': '#d0ddea',
  'border-secondary': '#D5E1EE',
  'border-dark': '#0F172A',

  background: '#FFFFFF',
  'background-body': '#f6f7fa',
  'background-input': '#fafcff',

  success: '#95AE0A',
  warning: '#CFA609',
  danger: '#F04D0C',
};

export default ipintegrated;
