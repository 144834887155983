import UserContext, { initialUserData, userReducer } from 'context/user';
import React, { useReducer } from 'react';

const FiltersState: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialUserData);

  return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};

export default FiltersState;
