import React, { createContext } from 'react';
import { Helmet } from 'react-helmet';
import { Brand } from 'types/branding';
import { useBranding } from 'hooks/useBranding';

export const BrandingContext = createContext<Brand>({} as Brand);

const getCssVars = (colors: { [key: string]: string }): string =>
  Object.entries(colors).reduce((css, entry) => {
    const [key, value] = entry;
    return `
        ${css}
        --${key}: ${value};
    `;
  }, ``);

const BrandingState: React.FC = ({ children }) => {
  const brand: Brand = useBranding();

  return (
    <>
      <Helmet>
        <meta name="description" content={brand.pageTitle} />
        <link rel="shortcut icon" href={brand.favicon} />
        <link rel="apple-touch-icon" href={brand.favicon} />
        <title>{brand.pageTitle}</title>
        <style>{`
          :root {
            ${getCssVars(brand.colors)}
            --brand: '${brand.name}';
          }
        `}</style>
      </Helmet>
      <BrandingContext.Provider value={brand}>{children}</BrandingContext.Provider>
    </>
  );
};

export default BrandingState;
