import * as React from 'react';
import { IconComponent } from 'types/icon';

const Icon: IconComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 20" {...props}>
    <path fill="none" d="M5,1 V18" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Icon;
