import { failure, RemoteData, success } from '@devexperts/remote-data-ts';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { AppError } from 'types/error';

const MOCK_NETWORK_CONDITIONS = false;

const networkConditionsMock = async () => {
  if (!MOCK_NETWORK_CONDITIONS) {
    return;
  }

  await new Promise(resolve => setTimeout(resolve, Math.round(Math.random() * 10000)));

  if (Math.floor(Math.random() * 3) === 0) {
    const loremIpsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer suscipit sagittis tellus, eget varius tellus volutpat ut. Vivamus dui purus, ultricies volutpat metus ut, viverra bibendum tellus. Morbi tortor ipsum, tristique et neque nec, pharetra semper tortor. Duis iaculis nisi et velit tincidunt facilisis. Aliquam sit amet vestibulum velit, vitae.`;
    const randomErrorMessage = loremIpsum
      .split(' ')
      .slice(0, Math.floor(Math.random() * 50))
      .join(' ');
    throw new Error('Mock Error: ' + randomErrorMessage);
  }
};

export const apiUrl: string = process.env.REACT_APP_API_URL || '/api/';
export const apiMock: boolean = process.env.REACT_APP_API_MOCK === 'true';

const globalConfig: AxiosRequestConfig = {
  withCredentials: true,
};

const isAxiosError = (error: any): error is AxiosError => error.hasOwnProperty('response');

const resolveStrings = (obj: string | { [index: string]: any }): string =>
  typeof obj !== 'string' && Object.keys(obj)
    ? Object.keys(obj)
        .map(key => obj[key])
        .map(resolveStrings)
        .filter(text => !!text.length)
        .join(', ')
    : String(obj);

const normalizeError = (error: Error | AxiosError): AppError => {
  if (isAxiosError(error) && error.response !== undefined) {
    const {
      response: { status, statusText, data },
    } = error;

    return {
      code: status,
      message: data ? resolveStrings(data) : statusText,
    };
  }

  return { code: 0, message: error.message };
};

export const request = async <T>(config: AxiosRequestConfig): Promise<RemoteData<AppError, T>> => {
  const richConfig = { ...config, ...globalConfig };

  try {
    if (config.url !== '/api/user') {
      await networkConditionsMock();
    }

    const response = await axios(richConfig);

    return success(response.data);
  } catch (error) {
    return failure(normalizeError(error));
  }
};
