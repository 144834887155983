import FiltersContext, { filtersReducer, initialFiltersData } from 'context/filters';
import React, { useReducer } from 'react';

const FiltersState: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(filtersReducer, initialFiltersData);

  return <FiltersContext.Provider value={{ state, dispatch }}>{children}</FiltersContext.Provider>;
};

export default FiltersState;
