import moment from 'moment';
import React, { createContext } from 'react';
import { DisplayBy } from 'types/analytics';
import { Period } from 'types/analytics';
import { DateRange } from 'types/date';
import { SMSC } from 'types/smsc';
import { Status } from './types';

export interface Action {
  type: 'set' | 'reset';
  payload: Partial<FiltersData>;
}

export interface FiltersData {
  country?: string;
  customerId?: string;
  customerShorts: string[];
  customersIds: string[];
  dateRange: DateRange;
  displayBy: DisplayBy;
  errorCodes: string[];
  keywords?: string[];
  shortCode?: string;
  smsc?: SMSC;
  smscs: SMSC[];
  network?: string;
  period: Period;
  status: Status[];
  originator?: string;
  recipient?: string;
  calculateCost?: boolean;
}

export const initialFiltersData: FiltersData = {
  country: undefined,
  customerId: undefined,
  customerShorts: [],
  customersIds: [],
  dateRange: {
    end: moment()
      .add(1, 'day')
      .startOf('day')
      .subtract(0.01, 'second'),
    start: moment().startOf('month'),
  },
  displayBy: 'date',
  errorCodes: [],
  network: undefined,
  originator: undefined,
  period: 'month',
  recipient: undefined,
  smsc: undefined,
  smscs: [],
  status: [],
};

export const filtersReducer = (state: FiltersData, { type, payload }: Action): FiltersData => {
  switch (type) {
    case 'set':
      return { ...state, ...payload };
    case 'reset':
    default:
      return initialFiltersData;
  }
};

interface FiltersContext {
  state: FiltersData;
  dispatch: React.Dispatch<Action>;
}

export default createContext<FiltersContext>({ state: initialFiltersData, dispatch: () => null });
