import Routing from 'components/common/Routing/Routing';
import React from 'react';
import AppState from './components/states/AppState';
import FiltersState from './components/states/FiltersState';
import UserState from './components/states/UserState';
import BrandingState from './components/states/BrandingState';

const App: React.FC = () => (
  <BrandingState>
    <AppState>
      <UserState>
        <FiltersState>
          <Routing />
        </FiltersState>
      </UserState>
    </AppState>
  </BrandingState>
);

export default App;
