import ipintegratedLogomark from 'images/ipintegrated/logomark.png';
import ipintegratedLogotype from 'images/ipintegrated/logotype.png';
import ipintegratedFavicon from 'images/ipintegrated/favicon.png';
import ipcolors from './colors/ipintegrated';
import cloudalertLogomark from 'images/cloudalert/logomark.png';
import cloudalertLogotype from 'images/cloudalert/logotype.png';
import cloudalertFavicon from 'images/cloudalert/favicon.png';
import cloudcolors from './colors/cloudalert';
import fiveLinxLogomark from 'images/5linx/logomark.png';
import fiveLinxLogotype from 'images/5linx/logotype.png';
import fiveLinxFavicon from 'images/5linx/favicon.png';
import fiveLinxColors from './colors/5linx';

import { Brand } from 'types/branding';

const branding: { [key: string]: Brand } = {
  cloudalert: {
    name: 'cloudalert',
    colors: cloudcolors,
    favicon: cloudalertFavicon,
    imageAlt: 'Cloud Alert',
    logomark: cloudalertLogomark,
    logotype: cloudalertLogotype,
    pageTitle: 'Cloud Alert',
  },
  ipintegrated: {
    name: 'ipintegrated',
    colors: ipcolors,
    favicon: ipintegratedFavicon,
    imageAlt: 'IP Integrated',
    logomark: ipintegratedLogomark,
    logotype: ipintegratedLogotype,
    pageTitle: 'IP Integrated',
  },
  '5linxcomm': {
    name: '5linxcomm',
    colors: fiveLinxColors,
    favicon: fiveLinxFavicon,
    imageAlt: '5linx Communication Services Ltd.',
    logomark: fiveLinxLogomark,
    logotype: fiveLinxLogotype,
    pageTitle: '5linx',
  },
};

export default branding;
