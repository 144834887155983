import { fold, getOrElse } from '@devexperts/remote-data-ts';
import Screen from 'components/common/Screen';
import userContext, { initialUserData, UserData } from 'context/user';
import { pipe } from 'fp-ts/lib/pipeable';
import { useApi } from 'hooks/useApi';
import React, { Suspense } from 'react';
import { apiUrl } from 'services/api';
import { UserDataResponse } from 'types/user';
import Loader from '../Loader';
import RoutingContext from './RoutingContext';

const AuthorizedContent = React.lazy(() => import('./AuthorizedContent'));
const UnauthorizedContent = React.lazy(() => import('./UnauthorizedContent'));

const Routing: React.FC = () => {
  const [apiData, api] = useApi<UserDataResponse>();
  const {
    dispatch,
    state: { isActive },
  } = React.useContext(userContext);

  React.useEffect(() => {
    const payload = pipe(
      apiData,
      getOrElse(() => initialUserData)
    );
    const customerUpdatedPayload: UserData = payload.customer ? payload : { ...payload, customer: undefined };
    dispatch({ type: 'set', payload: customerUpdatedPayload });
  }, [apiData, dispatch]);

  const getData = React.useCallback(() => {
    api.request({ url: `${apiUrl}user` }).then(api.update);
  }, [api]);

  React.useEffect(() => {
    if (isActive) {
      return;
    }

    getData();
  }, [isActive, getData]);

  return (
    <Screen>
      <RoutingContext.Provider
        value={{
          loginApi: api,
          loginApiData: apiData,
        }}
      >
        <Suspense fallback={<Loader />}>
          {pipe(
            apiData,
            fold(
              () => <Loader />,
              () => <Loader />,
              () => <UnauthorizedContent />,
              () => <AuthorizedContent />
            )
          )}
        </Suspense>
      </RoutingContext.Provider>
    </Screen>
  );
};

export default Routing;
