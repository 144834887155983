import { detect } from 'detect-browser';
import moment from 'moment';
import 'moment/locale/en-gb';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';

console.log(process.env); // tslint:disable-line
console.log(detect()); // tslint:disable-line

moment.locale('en-gb');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
