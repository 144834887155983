import React, { createContext } from 'react';

export interface Action {
  type: 'set' | 'reset';
  payload: Partial<AppData>;
}

export interface AppData {
  menuOpen: boolean;
}

export const initialAppData: AppData = {
  menuOpen: false,
};

export const appReducer = (state: AppData, { type, payload }: Action): AppData => {
  switch (type) {
    case 'set':
      return { ...state, ...payload };
    case 'reset':
    default:
      return initialAppData;
  }
};

interface AppContext {
  state: AppData;
  dispatch: React.Dispatch<Action>;
}

export default createContext<AppContext>({ state: initialAppData, dispatch: () => null });
