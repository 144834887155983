import branding from 'branding/branding';
import { Brand } from 'types/branding';

const HOST_NAMES: string[] = ['ipintegrated', 'cloudalert', '5linxcomm'];
const DEFAULT_HOST: string = 'ipintegrated';

const BRAND_SUPPORT_EMAILS: string[] = [
  'support@ipintegrated.com',
  'support@cloudalert.cloud',
  'support@5linxcomm.com',
];
const DEFAULT_BRAND_SUPPORT_EMAIL = 'support@ipintegrated.com';

const getCurrentHost = (): string =>
  HOST_NAMES.find(hostName => window.location.host.includes(hostName)) || DEFAULT_HOST;

export const getBrandSupportEmailAddress = (): string =>
  BRAND_SUPPORT_EMAILS.find(email => email.includes(getCurrentHost())) || DEFAULT_BRAND_SUPPORT_EMAIL;

export const useBranding = (): Brand => branding[getCurrentHost()];
