import Line from 'components/svg/Line';
import React from 'react';
import styles from './Loader.module.scss';

const Loader: React.FC = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <Line className={styles.line} />
      <Line className={styles.line} />
      <Line className={styles.line} />
    </div>
  </div>
);

export default Loader;
